var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.stations,"items-per-page":25,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"mobile-breakpoint":"5","light":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.station",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.short_name))])]}},{key:"item.distance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.distance)+" km")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(" mdi-information ")])]}},{key:"item.map",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.showOnMap(item)}}},[_vm._v(" mdi-map-marker ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }