var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gmap-map',{ref:"maps",staticStyle:{"width":"100%","height":"700px"},attrs:{"zoom":12,"center":_vm.center,"options":{
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: true,
  }}},_vm._l((_vm.stations),function(station,index){return _c('gmap-marker',{key:index,attrs:{"position":station.position},on:{"click":function($event){return _vm.showStation(station)}}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }